<template>
  <modal
    name="metrics-article-modal"
    :adaptive="true"
    height="auto"
    width="100%"
    :maxWidth="973"
  >
    <div class="modal-table-title-container">
      <h3 class="modal-table-title">Text metrics</h3>
    </div>

    <simplebar>
      <div v-if="metrics" class="metrics-container">
        <div class="metrics-container-part">
          <p>
            <span class="title" >Water: </span>
            <span :class="getColor(getPercent(metrics.waterTextCount, metrics.wt))">{{ metrics.waterTextCount }} ({{ getPercent(metrics.waterTextCount, metrics.wt) }}%)</span>
          </p>
          <p>
            <span class="title" >Target words: </span>
            <span :class="getColor(getPercent(metrics.targetWordsCount, metrics.wt))">{{ metrics.targetWordsCount }} ({{ getPercent(metrics.targetWordsCount, metrics.wt) }}%)</span>
          </p>
          <p><span class="title">WT:</span><span>{{metrics.wt}}</span></p>
        </div>
        <div class="metrics-container-part">
          <p>
            <span class="title" >UWA:</span>
            <span :class="getColor(metrics.percentageUwa, true)">{{ metrics.absoluteUwa }} ({{ Math.trunc(metrics.percentageUwa) }}%)</span>
          </p>
          <p>
            <span class="title">UWW:</span>
            <span :class="getColor(metrics.allTheUserTextsPercentageUwa, true)">{{ metrics.allTheUserTextsAbsoluteUwa }} ({{ Math.trunc(metrics.allTheUserTextsPercentageUwa) }}%)</span>
          </p>
        </div>
      </div>
      <div class="metrics-container-lists">
        <List v-if="metrics.uniqueText" title="Target words" :list="metrics.uniqueText"/>
        <List v-if="metrics.uniqueText && metrics.waterText" title="Words usage"
              :list="[...metrics.uniqueText, ...metrics.waterText]"/>
      </div>
    </simplebar>
  </modal>
</template>

<script>
export default {
  name: "ArticleMetricsModal",
  props: ['metrics'],
  components: {
    List: () => import('@/components/articles/ArticleBlockList'),
  },
  methods: {
    getPercent(val, total) {
      return Math.trunc((val / total) * 100)
    },
    getColor(val, reverse = false) {
      if (val < 33.3) {
        return reverse ? 'c-red' : 'c-green';
      } else if (val >= 33.3 && val <= 66.6) {
        return 'c-orange';
      } else {
        return reverse ? 'c-green' : 'c-red';
      }
    },
  }
}
</script>

<style scoped lang="scss">
.metrics-container {
  padding: 24px;
  display: flex;
}
.metrics-container-part {
  flex: 1;
  .title {
    margin-right: 16px;
    color: $c-light-blue;
    width: 150px;
    display: inline-block;
  }
  p {
    font-weight: 600;
  }
}
.metrics-container-lists {
  display: flex;
  padding: 0 16px 24px;
  .article-list-block {
    margin: 0 8px;
    flex: 1
  }
}
</style>

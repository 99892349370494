import { render, staticRenderFns } from "./ArticleKeyWords.vue?vue&type=template&id=04622dae&scoped=true&"
import script from "./ArticleKeyWords.vue?vue&type=script&lang=js&"
export * from "./ArticleKeyWords.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04622dae",
  null
  
)

export default component.exports
<template>
  <div class="article-page df">
    <Preloader v-if="pending"/>
    <section class="article-page-main">
      <div class="article-page-text-block block">
        <div class="article-page-header block-wrapper header df">
          <h1 class="article-page-title">{{ article.title || '--' }}</h1>
          <span class="lang">{{ article.language || '--' }}</span>
          <button class="btn blue" :disabled="!checkEdit" @click="toggleEditor">
            {{ edit ? 'Close edit (without save)' : 'Edit' }}
          </button>
          <button class="btn purple" @click="openRevisions">Revs</button>
          <button class="btn red" @click="checkArticle">Check</button>
        </div>
        <div
          v-if="!edit"
          ref="articleText"
          class="article-page-content block-wrapper editor"
          v-html="article.text || '--'"
        >
        </div>
        <div v-else class="article-page-content block-wrapper editor">
          <Input v-model.trim="$v.title.$model" @input="countSymbols" label="Edit title" placeholder="Name of article"/>
          <div class="article-create-title-symbols-container">
            <p class="article-create-title-symbols">symbols: {{ titleSymbols }}</p>
          </div>
          <div class="article-page-tags">
            <!--                      <Dropdown :list="translate" placeholder="no tags" label="Translate:" :value="translateVal" @selected="selectTranslateTag" />-->
            <div class="article-page-langs-container">
              <button class="article-page-lang" :class="{active: lang.value === article.language}"
                      @click="setLang(article.language)">
                Original
              </button>
              <button
                v-for="(l, idx) in listLangFiltered"
                :key="`langs-${idx}`"
                class="article-page-lang"
                :class="{active: lang.value === l.value}"
                @click="setLang(l.value)"
              >{{ l.name }}
              </button>
            </div>
          </div>
          <Editor v-model="text"/>
          <button class="btn blue article-page-save" :disabled="$v.$invalid" @click="save">Save and check text</button>
        </div>
      </div>
      <List v-if="article.revision" :isLink="true" title="Sources of plagiarism" :list="plagiarism"/>
      <div class="article-page-lists df">
        <List v-if="article.revision" :selected="selectedKeyword" @onClick="selectKeyword" title="Target words"
              :list="article.revision.metrics.uniqueText"/>
        <List v-if="article.revision" :selected="selectedKeyword" @onClick="selectKeyword" title="Words usage"
              :list="[...article.revision.metrics.uniqueText, ...article.revision.metrics.waterText]"/>
      </div>
    </section>
    <aside class="article-page-aside">
      <div class="article-page-check-data block">
        <h2 class="block-wrapper header">Check data </h2>
        <ul class="block-wrapper article-page-change-list">
          <li class="article-page-change-item">
            <p class="article-page-change-col">Status</p>
            <p v-if="data.status" class="article-page-change-col"
               :class="{
              'c-green': data.status === 'READY' || data.status === 'PUBLIC',
              'c-red': data.status === 'LOCKED'
            }">
              {{ data.status === 'UNLOCKED' ? 'Wait' : data.status.toLowerCase() }}
            </p>
          </li>
          <li class="article-page-change-item">
            <p class="article-page-change-col">Water</p>
            <p
              v-if="article.revision"
              class="article-page-change-col"
              :class="getColor(getPercent(article.revision.metrics.waterTextCount, article.revision.metrics.wt))"
            >
              {{ article.revision.metrics.waterTextCount }}
              ({{ getPercent(article.revision.metrics.waterTextCount, article.revision.metrics.wt) }}%)
            </p>
            <p v-else class="article-page-change-col">--</p>
          </li>
          <li class="article-page-change-item">
            <p class="article-page-change-col">Target words</p>
            <p
              v-if="article.revision"
              class="article-page-change-col"
              :class="getColor(getPercent(article.revision.metrics.targetWordsCount, article.revision.metrics.wt))"
            >
              {{ article.revision.metrics.targetWordsCount }}
              ({{ getPercent(article.revision.metrics.targetWordsCount, article.revision.metrics.wt) }}%)
            </p>
            <p v-else class="article-page-change-col">--</p>
          </li>
          <li class="article-page-change-item">
            <p class="article-page-change-col">WT</p>
            <p
              v-if="article.revision"
              class="article-page-change-col"
            >
              {{ article.revision.metrics.wt }}
            </p>
            <p v-else class="article-page-change-col">--</p>
          </li>
          <li class="article-page-change-item clickable"
              @click="highlightMetric('uniqueText')"
              :class="{active: selectedMetricType === 'uniqueText'}"
          >
            <p class="article-page-change-col">UWA</p>
            <p
              v-if="article.revision"
              class="article-page-change-col"
              :class="getColor(article.revision.metrics.percentageUwa, true)"
            >
              {{ article.revision.metrics.absoluteUwa }} ({{ Math.trunc(article.revision.metrics.percentageUwa) }}%)
            </p>
            <p v-else class="article-page-change-col">--</p>
          </li>
          <li class="article-page-change-item clickable"
              @click="highlightMetric('uwwTextFrequency')"
              :class="{active: selectedMetricType === 'uwwTextFrequency'}"
          >
            <p class="article-page-change-col">UWW</p>
            <p
              v-if="article.revision"
              class="article-page-change-col"
              :class="getColor(article.revision.metrics.allTheUserTextsPercentageUwa, true)"
            >
              {{ article.revision.metrics.allTheUserTextsAbsoluteUwa }}
              ({{ Math.trunc(article.revision.metrics.allTheUserTextsPercentageUwa) }}%)
            </p>
            <p v-else class="article-page-change-col">--</p>
          </li>
          <!--          <li class="article-page-change-item">-->
          <!--            <p class="article-page-change-col">TEST.RU (TRANS)</p>-->
          <!--            <p v-if="article.revision" class="article-page-change-col" v-html="getTextRuTrans"></p>-->
          <!--            <p v-else class="article-page-change-col">&#45;&#45;</p>-->
          <!--          </li>-->
          <li class="article-page-change-item">
            <p class="article-page-change-col">TEST.RU</p>
            <p v-if="article.revision" class="article-page-change-col">{{ getTextRu }}</p>
            <p v-else class="article-page-change-col">--</p>
          </li>
          <!--          <li class="article-page-change-item">-->
          <!--            <p class="article-page-change-col">TEST.RU (NODOM)</p>-->
          <!--            <p class="article-page-change-col c-green">{{ article.check.test.nodom }}%</p>-->
          <!--          </li>-->
        </ul>
      </div>
      <div class="article-page-keys block">
        <div class="block-wrapper header article-page-block">
          <h2>Article keys</h2>
          <button v-if="checkEdit" class="article-page-edit" @click="editKeys">Edit keys</button>
        </div>
        <div class="block-wrapper">
          <p class="article-page-keys-title">General</p>
          <ul v-if="keysGeneral" class="article-page-keys-list">
            <li class="article-list-block-item" v-for="(key, idx) in keysGeneral" :key="`list-general-${idx}`"
                :class="{active: key.keyword === selectedKeyword}"
            >
              <span class="counter">{{ idx + 1 }}</span>
              <span @click="selectKeyword(key.keyword)">{{ key.keyword }}</span>
              <span class="value">{{ key.count }}</span>
            </li>
          </ul>
          <p v-else class="article-list-block-item">--</p>
          <p class="article-page-keys-title">Slave</p>
          <ul v-if="keysSlave" class="article-page-keys-list">
            <li class="article-list-block-item" v-for="(key, idx) in keysSlave"
                :key="`list-slave-${idx}`" :class="{active: key.keyword === selectedKeyword}">
              <span class="counter">{{ idx + 1 }}</span>
              <span @click="selectKeyword(key.keyword)">{{ key.keyword }}</span>
              <span class="value">{{ key.count }}</span>
            </li>
          </ul>
          <p v-else class="article-list-block-item">--</p>
        </div>
      </div>
      <Comments :disabled="!checkEdit" :id="data.id"/>
    </aside>
    <ArticleKeyWords
      :id="data.id"
      :general="keysGeneral && keysGeneral.map(k => k.keyword)"
      :slave="keysSlave && keysSlave.map(k => k.keyword)"
    />
    <ArticleRevisionsModal :id="data.id"/>
    <ArticleMetricsModal :metrics="tempMetrics"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Preloader from '@/components/common/Preloader';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import languages from '@/utils/languages';
import ArticleKeyWords from '@/components/modals/ArticleKeyWords';
import {eventBus} from '@/main';
import ArticleRevisionsModal from '@/components/modals/ArticleRevisionsModal';
import ArticleMetricsModal from '@/components/modals/ArticleMetricsModal';
import Mark from 'mark.js';

export default {
  name: "ArticlePage",
  components: {
    ArticleMetricsModal,
    ArticleRevisionsModal,
    ArticleKeyWords,
    Preloader,
    List: () => import('@/components/articles/ArticleBlockList'),
    Comments: () => import('@/components/articles/ArticleComments'),
    Input: () => import('@/components/common/AInput'),
    Editor: () => import('@/components/common/AEditor'),
    // Dropdown: () => import('@/components/common/ADropdown'),
  },
  mixins: [validationMixin],
  validations: {
    title: {
      required
    },
  },
  data() {
    return {
      edit: false,
      text: '',
      title: '',
      data: {},
      // translateVal: null,
      // lang: 'orig',
      // translate: [
      //   {
      //     id: 1,
      //     data: 'tag 1'
      //   }
      // ],
      titleSymbols: 0,
      listLang: languages,
      lang: {},
      article: {},
      versions: [],
      pending: false,
      tempMetrics: {},
      selectedKeyword: null,
      selectedMetricType: null,
    }
  },
  watch: {
    lang: {
      handler(state, prevState) {
        this.text = state.value === this.article.language ? this.article.text : '';
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['user', 'prevUser']),
    checkEdit() {
      if (this.user.role === 'USER') {
        return this.data.status === 'UNLOCKED'
      } else {
        return this.data.status !== 'DELETED'
      }
    },
    listLangFiltered() {
      return this.listLang.filter(l => l.value !== this.article.language);
    },
    keysGeneral() {
      const {keywords} = this.data;
      if (keywords && keywords.length > 0) {
        return keywords.filter(k => k.type === 'GENERAL').map(k => {
          return {
            ...k,
            count: this.getCountKeyWord(k.keyword)
          }
        })
      }
      return null;
    },
    keysSlave() {
      const {keywords} = this.data;
      if (keywords && keywords.length > 0) {
        return keywords.filter(k => k.type === 'SLAVE').map(k => {
          return {
            ...k,
            count: this.getCountKeyWord(k.keyword)
          }
        })
      }
      return null;
    },
    plagiarism() {
      if (this.article.revision) {
        const {textru_metrics} = this.article.revision;
        if (textru_metrics && textru_metrics.textru_metrics) {
          const parsedTextRu = JSON.parse(textru_metrics.textru_metrics);
          const parsedResult = JSON.parse(parsedTextRu.json_result);
          return parsedResult.urls.map(u => ({
            word: u.url,
            count: `${u.plagiat} %`
          }))
        }
        return null;
      }
      return null;
    },
    getTextRu() {
      if (this.article.revision) {
        const {textru_metrics} = this.article.revision;
        if (textru_metrics && textru_metrics.textru_metrics) {
          const parsedTextRu = JSON.parse(textru_metrics.textru_metrics);
          return `${Math.trunc(+parsedTextRu.text_unique)}%`;
        } else {
          return '--';
        }
      }
      return null;
    },
    // getTextRuTrans() {
    //   if (this.article.revision) {
    //     const {textru_metrics} = this.article.revision;
    //     const metrics = textru_metrics.filter(tr => tr.language !== this.article.language).map((tr) => {
    //       if (!tr.textru_metrics) {
    //         return `${tr.language.toUpperCase()}: --`;
    //       }
    //       const parsedTextRu = JSON.parse(tr.textru_metrics);
    //       return `${tr.language.toUpperCase()}: ${Math.trunc(+parsedTextRu.text_unique)}%`
    //     });
    //     return metrics.length > 0 ? metrics.join('<br />') : '--';
    //   }
    //   return null;
    // },
    words() {
      if (this.article.text) {
        const expressions = ['<strong>', '</strong>', '<i>', '</i>', '<s>', '</s>'];

        let cont = this.article.text;
        expressions.forEach(e => {
          cont = cont.replaceAll(e, '');
        });
        cont = cont.replace(/<[^>]*>/g, ' ').replace(/&nbsp;/gi, '')
          .replace(/[.*+?^${}()|[\]\\;:«»,!—-]/gi, ' ').replace(/\s{2,}/g, ' ').trim();

        return cont.split(' ');
      }
      return [];
    }
  },
  methods: {
    ...mapActions(['getArticle', 'updateArticle', 'checkArticleMetrics']),
    countSymbols(text) {
      this.titleSymbols = text.trim().length
    },
    async save() {
      this.pending = true;
      await this.updateArticle({
        text: this.text,
        text_id: this.data.id,
        title: this.title,
        language: this.lang.value
      }).then(async () => {
        this.$notify({
          title: 'Update article',
          text: 'Article updated successfully',
          type: 'success'
        });
        this.edit = false;
        await this.loadArticle();
      }).catch(error => {
        this.$notify({
          title: 'Update article',
          text: error.response.data.message,
          type: 'error'
        });
        this.pending = false;
      });
    },
    async editKeys() {
      this.instance.unmark()
      this.$modal.show('article-keys-modal');
    },
    openRevisions() {
      this.$modal.show('revisions-article-modal');
    },
    async checkArticle() {
      this.pending = true;
      await this.checkArticleMetrics({
        text_id: this.article.id,
        text: this.text,
        language: this.lang,
        author_id: this.data.user_id
      }).then(data => {
        this.tempMetrics = data;
        this.pending = false;
        this.$modal.show('metrics-article-modal');
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Check article',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    highlightMetric(type) {
      this.selectedKeyword = null;
      this.instance.unmark();
      if (this.selectedMetricType === type) {
        this.selectedMetricType = null;
        return;
      } else {
        this.selectedMetricType = type;
      }
      const words = this.article?.revision?.metrics[type] || null;

      console.log('log -', words?.find(w => w.word === '–'))

      if (words && !this.edit) {
        words.forEach(w => {
          this.instance.mark(w.word, {
            element: 'span',
            separateWordSearch: false,
            className: 'marked-full',
            accuracy: {
              value: 'exactly',
              limiters: [':', ';', '.', ',', '_', '(', ')', '{', '}', '[', ']', '!', '\'', '"', '+', '=']
            }
          });
          // this.instance.mark(w.word, {
          //   element: 'span',
          //   separateWordSearch: false,
          //   className: 'marked',
          //   accuracy: {
          //     value: 'partially',
          //     limiters: [':', ';', '.', ',', '_', '(', ')', '{', '}', '[', ']', '!', '\'', '"', '+', '=']
          //   },
          //   ignorePunctuation: [':', ';', '.', ',', '_', '(', ')', '{', '}', '[', ']', '!', '\'', '"', '+', '=']
          // });
        });
      } else {
        this.selectedMetricType = null
      }
      // if (words) {
      //   let tempText = this.article.text;
      //   words.forEach(w => {
      //     const re = new RegExp(`\\b[^0-9\\p{L}>\\- ]?(?!<)${w.word}(?!>)[^0-9\\p{L}<\\- ]?\\b`, 'gimu');
      //     switch (type) {
      //       case 'uniqueText':
      //         tempText = tempText.replace(re, `<span class="marked">$&</span>`)
      //         break;
      //       case 'uwwTextFrequency':
      //         tempText = tempText.replace(re, `<span class="marked-full">$&</span>`)
      //         break;
      //     }
      //   });
      //
      //   this.textHighlighted = tempText;
      // } else {
      //   this.textHighlighted = null;
      // }
    },
    selectKeyword(keyword) {
      const {edit, article, selectedKeyword, text} = this;
      this.selectedKeyword = keyword === selectedKeyword ? null : keyword;
      this.selectedMetricType = null;

      this.instance.unmark();
      if (this.selectedKeyword && !edit) {
        this.instance.mark(this.selectedKeyword, {
          element: 'span',
          separateWordSearch: false,
          className: 'marked-full',
          accuracy: {
            value: 'exactly',
            limiters: [':', ';', '.', ',', '_', '(', ')', '{', '}', '[', ']', '!', '\'', '"', '+', '=']
          }
        });
        const splitKeywords = this.selectedKeyword.split(' ').filter(s => s.length);
          if (splitKeywords.length > 0) {
            splitKeywords.forEach(sW => {
              this.instance.mark(sW, {
                element: 'span',
                separateWordSearch: false,
                className: 'marked',
                accuracy: {
                  value: 'partially',
                  limiters: [':', ';', '.', ',', '_', '(', ')', '{', '}', '[', ']', '!', '\'', '"', '+', '=']
                },
                ignorePunctuation: [':', ';', '.', ',', '_', '(', ')', '{', '}', '[', ']', '!', '\'', '"', '+', '=']
              });
            })
          }
      }
      // if (this.selectedKeyword) {
      //   let tempText = article.text;
      //
      //
      //   let re = new RegExp(`\\b[^0-9\\p{L}>\\- ]?(?!<)${this.selectedKeyword}(?!>)[^0-9\\p{L}<\\- ]?\\b`, 'gimu')
      //   tempText = tempText.replaceAll(re, `<span class="marked-full">$&</span>`)
      //   const splitKeywords = this.selectedKeyword.split(' ').filter(s => s.length);
      //   if (splitKeywords.length > 0) {
      //     splitKeywords.forEach(w => {
      //       re = new RegExp(`\\b[^0-9\\p{L}>\\- ]?(?!<)${w}(?!>)[^0-9\\p{L}<\\- ]?\\b`, 'imu')
      //       tempText = tempText.replace(re, `<span class="marked">$&</span>`)
      //     });
      //   }
      //   this.textHighlighted = tempText;
      // } else {
      //   this.textHighlighted = null;
      // }
    },
    getCountKeyWord(word) {
      const re = new RegExp(word, 'gi')
      return ((this.article.text || '').match(re) || []).length;
    },
    toggleEditor() {
      this.edit = !this.edit;
      this.instance.unmark();
      this.selectedKeyword = null;
      this.selectedMetricType = null;
      this.titleSymbols = this.article.title.length
    },
    getPercent(val, total) {
      return Math.trunc((val / total) * 100)
    },
    getColor(val, reverse = false) {
      if (val < 33.3) {
        return reverse ? 'c-red' : 'c-green';
      } else if (val >= 33.3 && val <= 66.6) {
        return 'c-orange';
      } else {
        return reverse ? 'c-green' : 'c-red';
      }
    },
    setLang(lang) {
      this.lang = this.listLang.find(l => l.value === lang);
    },
    // selectTranslateTag(val){
    //   this.translateVal = val;
    // },
    async loadArticle() {
      if (this.instance) {
        this.instance.unmark()
      }
      const {id} = this.$route.params;
      this.pending = true;
      await this.getArticle(id).then(data => {
        this.data = data;
        this.versions = data.text_versions.sort((a, b) => this.$moment(b.updated_at).valueOf() - this.$moment(a.updated_at).valueOf());
        this.article = this.versions.find(a => a.is_active);
        this.title = this.article.title;
        this.text = this.article.text;
        this.lang = this.listLang.find(l => l.value === this.article.language);
        this.pending = false;
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Get article',
          text: error.response && error.response.data.message,
          type: 'error'
        });
        this.$router.replace('/dashboard/articles')
      });
    }
  },
  mounted() {
    const textContainer = this.$refs['articleText'];
    this.instance = new Mark(textContainer);
  },
  async created() {
    await this.loadArticle();
    eventBus.$on('onLoadArticle', async data => {
      await this.loadArticle();
    });
  }
}
</script>

<style scoped>

</style>

<template>
  <modal
    name="article-keys-modal"
    @before-open="beforeOpen"
    @closed="clear"
    :adaptive="true"
    height="auto"
    width="100%"
    :maxWidth="672"
    class="modal-table"
  >
    <div class="modal-table-title-container">
      <h3 class="modal-table-title">Edit article keys</h3>
    </div>
    <div class="modal-keys-container">
      <Preloader v-if="pending" />
      <ATextArea v-model.trim="generalText" label="General" />
      <ATextArea v-model.trim="slaveText" label="Slave" />
      <button @click="save" class="btn blue">Save keys</button>
    </div>
  </modal>
</template>

<script>
import modal from '@/mixins/modal';
import ATextArea from '@/components/common/ATextArea';
import {mapActions} from 'vuex';
import Preloader from '@/components/common/Preloader';
import {eventBus} from '@/main';

export default {
  name: "ArticleKeyWords",
  components: {Preloader, ATextArea},
  mixins: [modal],
  props: ['slave', 'general', 'id'],
  data(){
    return {
      slaveText: '',
      generalText: '',
      pending: false
    }
  },
  methods: {
    ...mapActions(['setArticleKeywords']),
    async save(){
      this.pending = true;
      let arrSlave = this.slaveText.replace('\n',' ').split(' ').filter(t => t.length !== 0);
      let arrGeneral = this.generalText.replace('\n',' ').split(' ').filter(t => t.length !== 0);
      await this.setArticleKeywords({
        id: this.id,
        general: arrGeneral,
        slave: arrSlave
      }).then(() => {
        this.pending = false;
        eventBus.$emit('onLoadArticle');
        this.$notify({
          title: 'Edit keys',
          text: 'Keys edited successfully',
          type: 'success'
        });
        this.close();
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Edit keys',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    beforeOpen(){
      this.slaveText = this.slave ? this.slave.join(' ') : '';
      this.generalText = this.general ? this.general.join(' ') : '';
    },
    clear() {
      // this.setTempUser(null);
      this.modalActive = false;
      this.slaveText = '';
      this.generalText = '';
      this.pending = false;
    },
    close() {
      this.$modal.hide('article-keys-modal');
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <tr>
    <td class="revision-version-date">
      <p>{{ $moment(item.created_at).format('YYYY-MM-DD') }}</p>
      <p>{{ $moment(item.created_at).format('HH:MM:ss') }}</p>
    </td>
    <td>
      <div class="revision-type-version">
        <button class="btn" :class="{transparent: showAllDiff}" @click="showAllDiff = false">Last</button>
        <button class="btn" :class="{transparent: !showAllDiff}" @click="showAllDiff = true">All</button>
      </div>
    </td>
    <td>
      <p class="changes" :class="{'c-green': !checkChanges, 'c-red': checkChanges}">{{!checkChanges ? 'No changes' : 'Has changes'}}</p>
    </td>
    <td><p v-html="showDiff"></p></td>
    <td>
      <p>TW: {{ item.metrics.targetWordsCount }} / UWA: {{ Math.trunc(item.metrics.percentageUwa) }} / UWW:
        {{ Math.trunc(item.metrics.allTheUserTextsPercentageUwa) }} / TRU: {{ getTextRu }}</p>
      <!--            <p>{{getTextRuTrans(rev)}}</p>-->
    </td>
    <td>
      <button class="btn" @click="setRevision">Set</button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "ArticleRevisionsRow",
  props: ['item'],
  data(){
    return {
      showAllDiff: false
    }
  },
  computed: {
    checkChanges(){
      if(this.showAllDiff){
        return this.item.allDiff ? this.item.allDiff > 0 : false
      } else {
        return this.item.lastDiff ? this.item.lastDiff > 0 : false
      }
    },
    showDiff(){
      if(this.showAllDiff){
        return this.item.allDiff ? `DIFF:<br/>${Math.trunc(this.item.allDiff)}%` : '--'
      } else {
        return this.item.lastDiff ? `DIFF:<br/>${Math.trunc(this.item.lastDiff)}%` : '--'
      }
    },
    getTextRu() {
      const {textru_metrics} = this.item;

      if (textru_metrics) {
        const parsedTextRu = JSON.parse(textru_metrics.textru_metrics);
        return `${Math.trunc(+parsedTextRu.text_unique)}%`;
      } else {
        return '--';
      }
    },
    // getTextRuTrans(rev) {
    //   const {textru_metrics} = rev;
    //   const metrics = textru_metrics.filter(tr => tr.language !== this.lang).map((tr) => {
    //     if (tr.textru_metrics) {
    //       const parsedTextRu = JSON.parse(tr.textru_metrics);
    //       return `${tr.language.toUpperCase()}: ${Math.trunc(+parsedTextRu.text_unique)}%`;
    //     } else {
    //       return `${tr.language.toUpperCase()}: --`;
    //     }
    //   });
    //   return metrics.length > 0 ? metrics.join(' / ') : '--';
    // },
  },
  methods: {
    setRevision() {
      this.$emit('rollback')
    }
  }
}
</script>

<style scoped>

</style>

export default {
  data(){
    return {
      modalActive: false,
    }
  },
  methods: {
    beforeOpen(){
      this.modalActive = true;
    },
    clear() {
      this.modalActive = false;
    }
  }
}

<template>
  <modal
    @before-open="getRevisions"
    @closed="pending = false"
    name="revisions-article-modal"
    :adaptive="true"
    height="auto"
    width="100%"
    :maxWidth="973"
    class="modal-table"
  >
    <div class="modal-table-title-container">
      <h3 class="modal-table-title">Text revision</h3>
    </div>

    <simplebar class="table-scroll">
      <Preloader v-if="pending"/>
      <table v-if="list.length > 0" class="revision-table">
        <tbody>
        <ArticleRevisionsRow
          @rollback="setRevision(rev.id)"
          v-for="(rev, idx) in revisions"
          :key="`session-${idx}`"
          :item="rev"
        />
        </tbody>
      </table>
      <div v-else class="table-empty">
        <p v-if="!pending">Empty</p>
      </div>
    </simplebar>
  </modal>
</template>

<script>
import {mapActions} from 'vuex';
import Preloader from '@/components/common/Preloader';
import ArticleRevisionsRow from '@/components/articles/ArticleRevisionsRow';
import {eventBus} from '@/main';

export default {
  name: 'ArticleRevisionsModal',
  components: {Preloader, ArticleRevisionsRow},
  props: ['id'],
  data() {
    return {
      list: [],
      pending: false
    }
  },
  computed: {
    revisions() {
      const list = this.list;
      return list.sort((a, b) => this.$moment(b.updated_at).valueOf() - this.$moment(a.updated_at).valueOf());
    }
  },
  methods: {
    ...mapActions(['getArticleRevisions', 'setArticleRevision']),
    async getRevisions() {
      this.pending = true;
      await this.getArticleRevisions(this.id).then(data => {
        this.list = data;
        this.pending = false;
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Article revisions',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    async setRevision(id) {
      this.pending = true;
      await this.setArticleRevision(id).then(async () => {
        this.$notify({
          title: 'Set article revisions',
          text: 'Revision was rollback successfully',
          type: 'success'
        });
        eventBus.$emit('onLoadArticle');
        this.$modal.hide('revisions-article-modal');
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Set article revisions',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
  }
}
</script>

<style lang="scss">
.revision-table {
  width: 100%;

  p {
    line-height: 18px;
  }

  tr {
    border-bottom: 1px solid $c-grey;
  }

  td {
    padding: 18px 8px;
    vertical-align: middle;
  }

  td:first-child {
    padding-left: 24px;
  }

  .btn {
    padding: 8px 16px;
  }

  .revision-type-version {
    display: flex;
    align-items: center;

    .btn {
      margin-right: 5px;
    }
  }

  .changes {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
